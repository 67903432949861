import React from 'react';
import Slider from 'react-slick';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import InnerImageZoom from 'react-inner-image-zoom'
import './DetailsSlider.scss';
import {uniqueId} from 'lodash';

const DetailsSlider = ({ product }) => {
    const settings = {
        customPaging: function(i) {
            return (
                <a>
                    <img src={product?.images?.[i]?.url} />
                </a>
            );
        },
        arrows: false,
        dots: true,
        dotsClass: 'slick-dots slick-thumb',
        infinite: true,
        speed: 100,
        swipe: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Slider {...settings}>
            {
                product?.images?.map(item => (
                    <InnerImageZoom
                        key={uniqueId()}
                        src={item?.url}
                        alt='details image'
                        zoomType={'hover'}
                    />
                ))
            }
        </Slider>
    );
}

export default DetailsSlider;