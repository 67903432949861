import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveShippingInfo } from "../../actions/cartAction";
import { useAlert } from "react-alert";
import { Country, State } from "country-state-city";
import ProductInfoCard from "./ProductInfoCard";
import "./checkout.scss";

const CheckoutSteps = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state);
  const userData = user?.user;

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState(shippingInfo?.firstName);
  const [lastName, setLastName] = useState(shippingInfo?.lastName);
  const [address, setAddress] = useState(shippingInfo.address);
  const [city, setCity] = useState(shippingInfo.city);
  const [state, setState] = useState(shippingInfo.state);
  const [country, setCountry] = useState(shippingInfo.country);
  const [pinCode, setPinCode] = useState(shippingInfo.pinCode);
  const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo);

  // useEffect(() => {

  //   // if (!user?.isAuthenticated) {
  //   //   alert.success("Login Required to purchase prouducts")
  //   //   navigate("/login")
  //   // }

  //   setEmail(user?.user?.email)
  // }, [user])

  const shippingSubmit = () => {
    // e.preventDefault();
    // console.log("phoneNo",phoneNo)

    if (
      phoneNo?.length < 10 ||
      phoneNo?.length > 10 ||
      typeof phoneNo === "undefined"
    ) {
      alert.error("Phone Number should be 10 digits Long");
      return;
    }
    // console.log("phoneNo-",phoneNo)

    dispatch(
      saveShippingInfo({
        email,
        firstName,
        lastName,
        address,
        city,
        state,
        country,
        pinCode,
        phoneNo,
      })
    );

    const subtotal = cartItems.reduce(
      (acc, item) => acc + item.quantity * item.price,
      0
    );
    const shippingCharges = 0;
    const tax = 0;

    const totalPrice = subtotal;

    const data = {
      subtotal,
      shippingCharges,
      tax,
      totalPrice,
    };

    sessionStorage.setItem("orderInfo", JSON.stringify(data));
    navigate("/payment");
  };

  return (
    <Fragment>
      <div className="checkout checkoutpg">
       {/* <div className="process-wrap active-step1">
          <div className="process-main">
            <div className="col-3 ">
              <div className="process-step-cont">
                <div className="process-step step-1" />
                <span className="process-label">Step 1</span>
                 <div class="process-dot-cont">
<div class="process-dots ship-process-dot-1"></div>
<div class="process-dots ship-process-dot-2"></div>
<div class="process-dots ship-process-dot-3"></div>
<div class="process-dots ship-process-dot-4"></div>
    </div>
              </div>
            </div>
            <div className="col-3 ">
              <div className="process-step-cont">
                <div className="process-step step-2" />
                <span className="process-label">Step 2</span>
              </div>
            </div>
            <div className="col-3">
              <div className="process-step-cont">
                <div className="process-step step-3" />
                <span className="process-label">Step 3</span>
              </div>
            </div>
            <div className="col-3">
              <div className="process-step-cont">
                <div className="process-step step-4" />
                <span className="process-label">Step 4</span>
              </div>
            </div>
          </div>
        </div>*/}
        <main>
          <div className="address__Details">
            <div className="header-row">
              <h4>Address Details</h4>
            </div>
            <div className="address__form">
              <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="group">
                <input
                  type="text"
                  placeholder="Pincode"
                  value={pinCode}
                  onChange={(e) => setPinCode(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Mobile"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                />{" "}
              </div>
              <div className="group">
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />{" "}
              </div>
              <input
                type="text"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />

              <select
                className="form-select"
                aria-label="Default select example"
                required
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option>Country / region</option>
                {Country &&
                  Country.getAllCountries().map((item) => (
                    <option key={item.isoCode} value={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <div className="group">
                <input
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />

                {country && (
                  <select
                    id="inputState"
                    className="form-select"
                    required
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option value="">State</option>
                    {State &&
                      State.getStatesOfCountry(country).map((item) => (
                        <option key={item.isoCode} value={item.isoCode}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
              {/* <span>
                <p>Create an account for later user</p>
                <input type="checkbox" />
              </span>
               <span>
                <input type="checkbox" id="ship" defaultChecked />
                <p>Ship to this address</p>
              </span> */}
            </div>
            <div className="available__address hidden">
              <h4>Select Address</h4>
              <div className="address">
                <input type="radio" />
                <div className="address-detail">
                  <div className="name">Name</div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Eaque, totam.
                  </p>
                </div>
                <button>Edit</button>
              </div>
              <div className="address">
                <input type="radio" />
                <div className="address-detail">
                  <div className="name">Name</div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Eaque, totam.
                  </p>
                </div>
                <button>Edit</button>
              </div>
            </div>
          </div>

          <div className="order__summary">
            <div className="header-row">
              <h4>Order Summary</h4>{" "}
            </div>
            <table>
              <thead>
                <tr>
                  <th className="left">PRODUCT NAME</th>
                  <th className="p-1">QTY</th>
                  <th>SUBTOTAL</th>
                </tr>
              </thead>
              <tbody>
                {cartItems &&
                  cartItems.map((item, i) => (
                    <tr key={i}>
                      <td className="product--desc">
                        {" "}
                        {item.name}
                        <span>{item.category}</span>
                      </td>
                      <td className="p-1 center">{item.qty}</td>
                      <td>INR {item.qty * item.price}</td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="left">
                    Subtotal
                  </td>
                  <td></td>
                  <td>{`INR ${cartItems.reduce(
                    (acc, item) => acc + item.qty * item.price,
                    0
                  )}`}</td>
                </tr>
                <tr>
                  <td className="left">
                    Shipping &amp; Handling [Weight Based]
                  </td>
                  <td></td>
                  <td>Free</td>
                </tr>
                <tr>
                  <td className="left">
                    <b>Grand Total</b>
                  </td>
                  <td></td>
                  <td>
                    <b>{`INR ${cartItems.reduce(
                      (acc, item) => acc + item.qty * item.price,
                      0
                    )}`}</b>
                  </td>
                </tr>
              </tfoot>
            </table>
            <div className="cta">
              <button className="primary-cta"> Proceed to Payment </button>
            </div>
          </div>

          {/*<div className="payment__method">
            <div className="header-row">
              <h4>Choose Payment Method</h4>{" "}
            </div>
            <div className="payment">
              <ul className="payment__options">
                <li className="payment__option active">
                  <div className="left--section">Cash On Delievery</div>
                  <div className="right--section">
                    <h5>COD</h5>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quia ab eos facilis. Illum ea dolorum necessitatibus,
                      explicabo quis animi provident?
                    </p>
                    <button onClick={shippingSubmit}>Place Order</button>
                  </div>
                </li>
                <li className="payment__option">
                  <div className="left--section">Cash On Delievery</div>
                  <div className="right--section">
                    <h5>COD</h5>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quia ab eos facilis. Illum ea dolorum necessitatibus,
                      explicabo quis animi provident?
                    </p>
                    <button onClick={shippingSubmit}>Place Order</button>
                  </div>
                </li>
                <li className="payment__option">
                  <div className="left--section">Cash On Delievery</div>
                  <div className="right--section">
                    <h5>COD</h5>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quia ab eos facilis. Illum ea dolorum necessitatibus,
                      explicabo quis animi provident?
                    </p>
                    <button onClick={shippingSubmit}>Place Order</button>
                  </div>
                </li>
                <li className="payment__option">
                  <div className="left--section">Cash On Delievery</div>
                  <div className="right--section">
                    <h5>COD</h5>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quia ab eos facilis. Illum ea dolorum necessitatibus,
                      explicabo quis animi provident?
                    </p>
                    <button onClick={shippingSubmit}>Place Order</button>
                  </div>
                </li>
              </ul>
            </div>
          </div>*/}
        </main>
      </div>
    </Fragment>
  );
};

export default CheckoutSteps;
