import Slider from 'react-slick';
import './Banner.scss';
const Banner = (props) => {

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
      <section className="banner">
        <Slider {...settings}>
          {
            props.banners.map((el, i) => (
                <img draggable="false" src={el} alt="banner" key={i} />
            ))
          }
        </Slider>
      </section>
  );
};

Banner.defaultProps = {
  banners: []
};

export default Banner;
