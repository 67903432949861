import React from 'react';
import { Link, NavLink  } from 'react-router-dom';
import { dirIcons } from '../../../Utilites/GetImage';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { logout } from '../../../actions/userAction';
import './Footer.scss';

const date = new Date()
const currentYear = date.getFullYear()

function Footer() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { cart, user } = useSelector((state) => state);
  const { cartItems } = cart;
  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };
  const onLogout = (e) => {
    e.preventDefault();

    dispatch(logout());
    alert.success('Logout Successfully');
  };

  return (
    <React.Fragment>
      <div className="footer-container">
        <div className="social">
          <div className="social-items">
            <a href="#"><img src={dirIcons['whatsapp-colored.svg']} alt="whatsapp" /></a>
          </div>
          <div className="social-items">
            <a href="#"><img src={dirIcons['fb-colored.svg']}  alt="fb" /></a>
          </div>
          <div className="social-items">
            <a href="#"><img src={dirIcons['instagram-colored.svg']} alt="insta" /></a>
          </div>
          <div className="social-items">
            <a href="#"><img src={dirIcons['google.png']} alt="google" /></a>
          </div>
          <div className="social-items">
            <a href="#"><img src= {dirIcons['twitter-colored.svg']} alt="twitter" /></a>
          </div>
        </div>
        <div id="mySidenav" className="sidenav">
          <a href="#" className="closebtn" onClick={()=>closeNav()}>×</a>
          <a href="#">Profile</a>
          <a href="/">Home</a>
          <a href="/wishlist">Wishlist</a>
          <a href="/cart">Cart</a>
          <a href="#" className="collapsible">Category</a>
          <ul className="collapsible-content">
            <li><a href="#">Link 1</a></li>
            <li><a href="#">Link 2</a></li>
            <li><a href="#">Link 3</a></li>
          </ul> <a href="#">Contact</a>
          <a href="#">About</a>
          { user?.isAuthenticated ?
              <a className="signout" href="#" onClick={(e) => onLogout(e)}>Sign Out</a> : ''
          }
        </div>
        <footer>
          <div className="form">
            <h1>Not a user?</h1>
            <div className="signup">
              <input type="text" placeholder="email address" />
              <button><a href="#">Sign Up</a></button>
            </div>
          </div>
          <div className="footer-links">
            <div className="links">
              <a href="#">Sitemap</a>
              <a href="#">Privacy Policy</a>
              <a href="#">ALEX Central</a>
              <a href="#">Careers</a>
            </div>
            <div className="links">
              <a href="#">Sitemap</a>
              <a href="#">Privacy Policy</a>
              <a href="#">ALEX Central</a>
              <a href="#">Careers</a>
            </div>
            <div className="links last">
              <a href="#">Products</a>
              <a href="#">About</a>
              <a href="#">Contact</a>
            </div>
          </div>
          <div className="social-links">
            <div className="links">
              <a href="#"><i className="fa fa-twitter fa-sm" /></a>
            </div>
            <div className="links">
              <a href="#"><i className="fa fa-google fa-sm" /></a>
            </div>
            <div className="links">
              <a href="#"><i className="fa fa-facebook fa-sm" /></a>
            </div>
            <div className="links">
              <a href="#"><i className="fa fa-instagram fa-sm" /></a>
            </div>
          </div>
          <div className="copyright"> Copyright @ All rights reserved The Novelty prints </div>
        </footer>
        <div className="menu-bar">
          <div className="menu">
            <NavLink to="/"><i className="fa fa-home fa-lg" /></NavLink>
          </div>
          <div className="menu">
            <a href="#"><span><i className="fa fa-th-large fa-lg" /></span></a>
          </div>
          <div className="menu">
            <NavLink to="/cart" id="cart">
              <span className="count">{cartItems?.length}</span>
              <i className="fa fa-shopping-cart fa-lg" />
            </NavLink>
          </div>
          <div className="menu">
            <a href="#"><i className="fa fa-clipboard fa-lg" /></a>
          </div>
          <div className="menu">
            <NavLink to="/customer-dashboard"><i className="fa fa-user fa-lg" /></NavLink>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default Footer
