import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'
import Home from './component/Home/Home';
import Header from './component/layout/Header/Header';
import Footer from './component/layout/Footer/Footer';
import Loader from './component/layout/Loader/Loader';
import LoginComponent from './component/User/Login/LoginComponent';
import RegisterComponent from './component/User/RegisterComponent';
import ForgotPwdComponent from './component/User/ForgotPwdComponent';
import Products from './component/Product/Products';
import ProductDetails from './component/Product/ProductDetails/ProductDetails';
import Cart from './component/Cart/Cart';
import TheBrand from './component/OtherComponent/TheBrand';
import PrivacyPolicy from './component/OtherComponent/PrivacyPolicy';
import TermCond from './component/OtherComponent/TermCond';
import About from './component/layout/About/About';
import Contact from './component/layout/Contact/Contact';
import CheckoutSteps from './component/Cart/CheckoutSteps';
import Shipping from './component/Cart/Shipping';
import Payment from './component/Cart/Payment';
import ConfirmOrder from './component/Cart/ConfirmOrder';
import OrderSuccess from './component/Cart/OrderSuccess';
import WishList from './component/Product/WishList/WishList';
import CustomerDashboard from './component/User/CustomerDashboard/CustomerDashboard';
import ResetPassword from './component/User/ResetPassword';
import LookBook from './component/layout/About/Lookbook';
import ExchangeRefund from './component/OtherComponent/ExchangeRefund';
import { useSelector } from 'react-redux';
import axios from 'axios';

import ProtectedRoute from './component/Route/ProtectedRoute';
// import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import store from './store';
import { loadUser } from "./actions/userAction";
import NotFound from './component/layout/Not Found/NotFound';
import API_URL from './config';

import StripePayment from './component/Cart/StripePayment';
import PaymentResponse from './Stripe/PaymentResponse';
import GoToTop from './Utilites/GoToTop';
import { dirImages } from './Utilites/GetImage';
import PayuFail from './payu-pg/PayuFail';
import PayuForm from './payu-pg/PayuForm';
import PayuSuccess from './payu-pg/PayuSuccess';
import CashFree from './cashfree/CashFree';



// const stripePromise = loadStripe("pk_test_51MEoOpSI6HA21QphwbUcAIsAX9R4oUpVouehToV1IHMnr5OrePQuokKgqeX3TZ6j9Prw1Z8An2QI01xMEUQw05gp00OoAo5SYh");


function AllRoutes() {

  const { isAuthenticated, user } = useSelector((state) => state.user);
  let navigate = useNavigate();
  


  const [stripeApiKey, setStripeApiKey] = useState("");

  async function getStripeApiKey() {
    // const { data } = await axiosInstanceCookie.get(API_URL.Stripeapikey);
    // setStripeApiKey(data.stripeApiKey);
  }

  useEffect(() => {
    // store.dispatch(loadUser()); 
    getStripeApiKey();
  }, []);


useEffect(() => {
  
   if (isAuthenticated){
      // return navigate("/");
   }
},[isAuthenticated]);



  // window.addEventListener("contextmenu", (e) => e.preventDefault());

  return (
    <>
      {/* <Loader /> */}
    
      <Header />
      {/* {isAuthenticated && <UserOptions user={user} />} */}

      {/* {stripeApiKey && (
        <Elements stripe={loadStripe(stripeApiKey)}> */}
          <Routes>
            {/* <Route exact path="/process/payment" element={<StripePayment />} /> */}
            {/* <Route exact path="/process/payment" element={<Payment />} /> */}
            <Route path="/" element={<Home />} />
            {/* <Route path="/site" element={<Home />} /> */}
            <Route exact path="/login" element={<LoginComponent />} />
            {/* <Route exact path="/register" element={<RegisterComponent />} /> */}
            {/* <Route exact path="/forgot-password" element={<ForgotPwdComponent />} />
            <Route exact path="/reset-password/:token" element={<ResetPassword />} />  */}
            {/* <ProtectedRoute exact path="/account" component={Profile} /> */}
            {/* <ProtectedRoute exact path="/me/update" component={UpdateProfile} /> */}
            {/* <ProtectedRoute exact path="/password/update" component={UpdatePassword} /> */}
            <Route exact path="/products/:category" element={<Products />} />
            {/* <Route exact path="/all-product/:category_id" element={<Unstitched />} /> */}
            <Route exact path="/product-details/:product_id" element={<ProductDetails />} />
            {/* <Route exact path="/unstitched" element={<Unstitched />} /> */}
            <Route exact path="/cart" element={<Cart />} />

            {/* new url created*/}

            <Route exact path="/checkout" element={<CheckoutSteps />} /> {/*1 */}
            {/* <Route exact path="/shipping" element={<Shipping />} />  */}
            <Route exact path="/payment" element={<Payment />} /> 
            {/* <Route exact path="/payment-response" element={<PaymentResponse />} />  */}
            {/* <Route exact path="/confirm-order/:order_id" element={<ConfirmOrder />} />  */}

            <Route exact path="/customer-dashboard" element={<CustomerDashboard />} />
            <Route exact path="/order" element={<OrderSuccess />} />
            <Route exact path="/wishlist" element={<WishList />} />
{/* 
            <Route exact path="/the-brand" element={<TheBrand />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/lookbook" element={<LookBook />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/term-condition" element={<TermCond />} />
            <Route exact path="/exchange-refund" element={<ExchangeRefund />} /> */}

            {/* payu routes */}
            {/* <Route exact path="/payment/pay-res-fail" element={<PayuFail />} />
            <Route exact path="/payment/pay-res-success" element={<PayuSuccess />} /> */}

            {/* cashfree */}
            {/* <Route exact path="/payment/cashfree/checkout" element={<CashFree />} /> */}
              <Route exact path="/contact" element={<Contact />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/term-condition" element={<TermCond />} />
              <Route exact path="/exchange-refund" element={<ExchangeRefund />} />


            <Route path="*" element={<NotFound />} />
            <Route
              // component={window.location.pathname === "/process/payment" ? NotFound : NotFound}
            />
          </Routes>
          {/* <Route exact path="/process/payment" element={Payment} /> */}
        {/* </Elements>
      )} */}
      <GoToTop />
    
      <Footer />
    </>
  );
}

export default AllRoutes
