import React, { useContext } from 'react';
import { AccordionContext } from './AccordionWrapper';
import './Accordion.scss';

const AccordionItem = (props) => {
    let indexPlus;
    const { active, setActive, type } = useContext(AccordionContext);

    const indexCount = (index) => {
        indexPlus = index + 1;
        return indexPlus;
    };

    const eventHandler = (e, index) => {
        e.preventDefault();

        if (active === index && type !== 'vertical' && type !== 'horizontal') {
            setActive(-1);
        } else {
            setActive(index);
        }
     }

    return (
        <div className="accordion-item">
            <h3 className="accordion-title">
                <button 
                    onClick={(e) => eventHandler(e, props.index)}
                    className={ active === props.index ? 'active' : 'inactive'}
                    aria-expanded={ active === props.index ? 'true' : 'false' }
                    aria-controls={ 'sect-' + indexCount(props.index) }
                >
                    <span className="title-wrapper">{props.title}
                    </span>  
                    <span className="icon-wrapper">
                        <span className={ active === props.index ? 'minus' : 'plus'}></span>
                    </span>
                </button>
            </h3>
            <div className="accordion-panel">
                <div id={ 'sect-' + indexCount(props.index) } className={ active === props.index  ? 'panel-open' : 'panel-close' }>
                    {props.description}
                </div>
            </div>
        </div>
    )
};

export default AccordionItem;
