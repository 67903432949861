import React, { useEffect,useState } from 'react'
import { useAlert } from 'react-alert';
import API_URL from '../config';
import { axiosInstanceCookie } from '../Utilites/Axios';

function PayuForm(props) {
    const { checkoutInfo } = props
    const alert = useAlert()

    const [clientSecret, setClientSecret] = useState("");
    const [nkey, setNkey] = useState("");
    const [TxnIdPayu, setTxnIdPayu] = useState("");

    const user = JSON.parse(localStorage.getItem("userecm"));
    const shippingInfo = JSON.parse(localStorage.getItem("shippingInfo"));
    const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));


    useEffect(() => {
      if (user && checkoutInfo) {
        const ecm_order_data = JSON.parse(sessionStorage.getItem("ecm_order"));
        if(ecm_order_data==="null"){
          alert.error("Kindly Select the product to make payment!");
        }else{
          
          const authToken = localStorage.getItem("authToken")
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`
            },
          };
              axiosInstanceCookie.post(API_URL.createHash, checkoutInfo ,config).then(
                (res)=>{
                  console.log(res)
                  setClientSecret(res?.data?.formData?.formBody?.client_secret)
                  setNkey(res?.data?.formData?.formBody?.nkey)
                  setTxnIdPayu(res?.data?.formData?.formBody?.payid)
                  document.getElementById("payu_checkout").submit();
                }
              ).catch(err=>{
                alert.error(err?.response?.data?.message)
              })
        }
       
          }
    }, [])
    
  // https://test.payu.in/_payment
  // https://secure.payu.in/_payment
    
    return (
        <div className="payu-form ">
            <form action='https://secure.payu.in/_payment' method="post" id="payu_checkout">
                <input type="hidden" name="key" value={nkey} />
                <input type="hidden" name="txnid" value={TxnIdPayu} />
                <input type="hidden" name="productinfo" value={checkoutInfo?.description} />
                <input type="hidden" name="amount" value={checkoutInfo?.totalPrice} />
                <input type="hidden" name="email" value={checkoutInfo?.user?.email} />
                <input type="hidden" name="firstname" value={checkoutInfo?.shippingInfo?.firstName} />
                <input type="hidden" name="lastname" value={checkoutInfo?.shippingInfo?.lastName} />
                <input type="hidden" name="surl" value={API_URL.responseHandle} />
                <input type="hidden" name="furl" value={API_URL.responseHandle} />
                <input type="hidden" name="phone" value={checkoutInfo?.shippingInfo?.phoneNo} />
                <input type="hidden" name="hash" value={clientSecret} />
                <input type="submit" value="submit" style={{display:"none"}} />
            </form>
        </div>
    )
}

export default PayuForm