import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  clearSearchProudctList,
  getProductDetails,
  newReview,
} from "../../../actions/productAction";
import { addItemsToCart, addItemsToWishlist, removeItemsFromWishlist } from "../../../actions/cartAction";
import { useAlert } from 'react-alert';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {dirIcons, dirImages} from "../../../Utilites/GetImage";
import Loader from '../../layout/Loader/Loader';
import parse from 'html-react-parser';
import { uniqueId } from 'lodash';
import DetailsSlider from'./DetailsSlider/DetailsSlider';
import './ProductDetails.scss';
import AccordionWrapper from "../../../reusable-component/accordion/AccordionWrapper";
import AccordionItem from "../../../reusable-component/accordion/AccordionItem";
import InnerImageZoom from "react-inner-image-zoom";
import Rating from "react-rating";

function ProductDetails() {

  const params = useParams();
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );


  // const product = {
  //   _id:1,
  //   image:"https://firebasestorage.googleapis.com/v0/b/thenoveltyprints-97bc9.appspot.com/o/products%2F1WM_L.png?alt=media&token=28cb91cc-0ffc-4318-941d-150e85caab2e",
  //   name:"couple mug", 
  //   price:"299", 
  //   Stock:"12",
  //   description:"lorem ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum "
  // }



  const { success, error: reviewError } = useSelector(
    (state) => state.newReview
  );


  const { products } = useSelector((state) => state.products);
  const { user } = useSelector((state) => state);
  const { isAuthenticated } = user

  const filterProduct = products?.filter(item => item._id !== params?.product_id)


  const [quantity, setQuantity] = useState(1);
  const [productSize, setProductSize] = useState("");
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [discription, setDiscription] = useState("<p>Not Found</p>");
  const [instruction, setInstruction] = useState("<p>Not Found</p>");
  const [sizeChartToggle, setSizeChartToggle] = useState(false)
  const [isWishlistItem, setIsWishlistItem] = useState(false)
  const [previewImage, setPriviewImage] = useState("#")




  const sizeArray = product?.size



  const increaseQuantity = () => {
    // console.log("dd")
    // console.log("product.Stock", product.Stock)
    // console.log("quantity", quantity)
    if (product.Stock <= quantity) return;
    // console.log("ee")
    const qty = quantity + 1;
    setQuantity(qty);
  };

  const decreaseQuantity = () => {
    if (1 >= quantity) return;

    const qty = quantity - 1;
    setQuantity(qty);
  };

  const addToCartHandler = (product) => {
    console.log(product)
    // console.log(product?.size)

    if (product?.size?.length > 0 && product?.size[0] !== "default") {

      if (productSize !== "") {
        console.log(params?.product_id)
        dispatch(addItemsToCart({ id: params?.product_id, quantity, productSize, isAuthenticated }));
        alert.success("Item Added To Cart")
        navigate("/cart");

      } else {
        alert.error("Please select the size")
      }
    } else {
      let productSize = product?.size[0]
      
      dispatch(addItemsToCart({ id: params?.product_id, quantity, productSize, isAuthenticated }));
      alert.success("Item Added To Cart")
      navigate("/cart");

    }


  };

  const { wishList } = useSelector((state) => state.cart);


  const deleteWishlistItem = (id) => {
    dispatch(removeItemsFromWishlist(id, isAuthenticated));
  };


  const submitReviewToggle = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const reviewSubmitHandler = () => {
    const myForm = new FormData();

    myForm.set("rating", rating);
    myForm.set("comment", comment);
    myForm.set("productId", params?.product_id);

    dispatch(newReview(myForm));

    setOpen(false);
  };

  const setPriviewImageFc = (e) => {
    setPriviewImage(e.attributes.datarel.nodeValue)
  }

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (reviewError) {
      alert.error(reviewError);
      dispatch(clearErrors());
    }

    // if (success) {
    //   alert.success("Review Submitted Successfully");
    //   dispatch({ type: NEW_REVIEW_RESET });
    // }
    dispatch(clearSearchProudctList())

    dispatch(getProductDetails(params?.product_id));

  }, [params, reviewError, success]);



  useEffect(() => {
    if (typeof (product?.description) !== 'undefined') {
      setDiscription(product?.description)
      setInstruction(product?.instruction)
      setPriviewImage(product?.image)

    }
  }, [product])


  useEffect(() => {
    const isProductExistWishlist = wishList?.filter((productItem) => productItem?.product === params?.product_id)
    if (isProductExistWishlist?.length > 0) {
      setIsWishlistItem(true)
    } else {
      setIsWishlistItem(false)

    }
  }, [wishList, params])


  const videoEl = useRef(null);
  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  const toggleWishlistItem = (status, productId) => {
    console.log(productId, status);
    if (status) {
      deleteWishlistItem(productId)
      // alert.success("Remove from the wishlist")
    } else {
      dispatch(addItemsToWishlist(productId))
      // alert.success("Added in wish list")
    }

  }

  useEffect(() => {
    attemptPlay();
  }, []);



  const checkVideoExtension = (mediaUrl) => {

    if (mediaUrl.includes(".mp4")) {
      return (
        <img src={dirImages["play-img.png"]} dataRel={mediaUrl} alt="video" onClick={(e) => setPriviewImageFc(e.target)} />
      )
    }
    return (<img src={mediaUrl} alt="img" dataRel={mediaUrl} onClick={(e) => setPriviewImageFc(e.target)} />)

  }


  return (
    <React.Fragment>
      {<div className="product-details">
        <ul className="breadcrumbs">
          <div className="breadcrumbs-bg">
            <li>
              <Link to="/" className="fas fa-home"></Link>
            </li>
            <li>
              <Link to={`/products/${product.category}`}>{product.category}</Link>
            </li>
            <li>
              {product.name}
            </li>
          </div>
        </ul>
        <div className="top-div">
          <div className="preview">
            {
              (product && product.images && product.images.length) ?
                  <DetailsSlider product={product}/> :
                  <div className="image-box">
                    <InnerImageZoom
                        src={previewImage}
                        alt='details image'
                        zoomType={'hover'}
                    />
                  </div>
            }

          </div>

          <div className="product-info">
            <div className="heading row">
              <h1>{product?.name}</h1>
            </div>
            <div className="rating row">
              <Rating
                  className="rating-wrapper"
                  readonly={true}
                  initialRating={4.5}
                  emptySymbol="fa fa-star-o"
                  fullSymbol="fa fa-star"
                  fractions={2}
              />
              <h3>(2)</h3>
            </div>
            {
              product?.size?.length > 1 &&
                <div className="row">
                  <h3>Size :</h3>
                  <div className="select-dropdown">
                    <select onChange={(e) => setProductSize(e.target.value)}>
                      {product?.size?.map((item, index) => (
                          <option key={index}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
            }

            <div className="row">
              <h3>Quantity : </h3>
              <div className="quantity">
                <button className="btn-minus" onClick={decreaseQuantity}>-</button>
                <input className="quantity-field" type="text" value={quantity} min={0} max={10} readOnly={true}/>
                <button className="btn-plus" onClick={increaseQuantity}>+</button>
              </div>
            </div>
            <div className="stock row">
              <h3> Status :</h3>
              <h4 className={product?.Stock < 1 ? 'out-of-stock' : 'in-stock'}>{ product?.Stock < 1 ? 'Sold out' : 'In Stock' }</h4>
            </div>
            <div className="price row">
              <h3> Price : </h3>
              <h4>INR {product?.price}</h4>
            </div>
            <div className="buttons row">
              <button
                  className="primary-cta"
                  disabled={product.Stock === 0}
                  onClick={() => addToCartHandler(product)}>Add to cart
              </button>
              <button className="primary-cta"
                  disabled={product.Stock === 0}
                  onClick={() => addToCartHandler(product)}>Buy Now
              </button>
            </div>
            <AccordionWrapper>
              <AccordionItem
                  title={'Description'}
                  index={0}
                  description={product?.description && parse(product?.description)}
              />
              <AccordionItem
                  title={'Instruction'}
                  index={1}
                  description={product?.instruction && parse(product?.instruction)}
              />
            </AccordionWrapper>
          </div>
        </div>
      </div>
      }
    </React.Fragment>
  )
}

export default ProductDetails