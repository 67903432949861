import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import React from "react";
import { uniqueId } from "lodash";
import './DealSlider.scss';

export const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    swipe: false,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};
const DealSlider = ({ title, tagline, productData }) => {
    return (
        <section className="deal-container">
            <div className="products">
                <div className="title">
                    <h1>{title}</h1>
                    <p>{tagline}</p>
                </div>
                <hr />
                <div className="slider-container">
                    <Slider {...settings}>
                        {productData?.map(item=>(
                            <div className="product" key={uniqueId()}>
                                <Link to={`/products/${item._id}`}>
                                    <img src={item?.additionalData} alt="product" className="img_custom" />
                                    <div className="about">
                                        <h2 className=""> {item?._id?.toUpperCase()} </h2>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default DealSlider;
