import React, { useEffect, useState } from "react";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "../../../actions/userAction";
import { dirImages, dirIcons } from "../../../Utilites/GetImage";

import {
  getProductList,
  clearSearchProudctList,
} from "../../../actions/productAction";
import { axiosInstanceCookie } from "../../../Utilites/Axios";
import HeaderStrip from './HeaderStrip/HeaderStrip';
import HeaderLogo from './HeaderLogo/HeaderLogo';
import HeaderMenu from './HeaderMenu/HeaderMenu';
import SearchBar from "./SearchBar/SearchBar";

import './header.scss';

function Header() {
  /*const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [price, setPrice] = useState([0, 60000]);
  const [category, setCategory] = useState("");
  const [ratings, setRatings] = useState(0);
  const [menuToggle, setMenuToggle] = useState(true);

  const { cart, user, searchProductList } = useSelector((state) => state);
  const { cartItems, wishList } = cart;
  const { searchProducts } = searchProductList;

  console.log("cartItems",cartItems)
  useEffect(() => {
    if (searchKeyword.length > 2) {
      dispatch(getProductList(searchKeyword, 1, price, category, ratings));
    }
    return () => {
      dispatch(clearSearchProudctList());
      setSearchKeyword("");
    };
  }, [searchKeyword, dispatch]);

  const logoutUser = () => {
    dispatch(logout());
  };

  const routePath = useLocation();

  useEffect(() => {
    setMenuToggle(false);
  }, [routePath]);

  /!* Set the width of the side navigation to 250px *!/
  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }*/

  return (
      <header>
        <HeaderStrip />
        <div className="header-container">
            <HeaderLogo />
            <SearchBar />
            <HeaderMenu />
        </div>
      </header>
  );
}
export default Header;
