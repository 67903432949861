import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  SAVE_SHIPPING_INFO,
  ADD_TO_WISHLIST,
  REMOVE_WISHLIST_ITEM
} from "../constants/cartConstants";
import API_URL from "../config";
import { axiosInstance, axiosInstanceCookie } from "../Utilites/Axios";

// Add to Cart


export const addItemsToCart = (objData) => async (dispatch, getState) => {
  console.log("objData",objData)
  
  const {id, quantity, productSize, color, isAuthenticated } = objData 
  const { data } = await axiosInstance.get(`${API_URL.GetProductDetails}/${id}`);


  const reqCartData = {
    product: data.product._id,
    name: data.product.name,
    price: data.product.price,
    image: data.product.image,
    stock: data.product.Stock,
    productCategory: data.product.category,
    qty: quantity,
    productSize,
    color: color
  };
  console.log("data",reqCartData)
  dispatch({
    type: ADD_TO_CART,
    payload: reqCartData,
  });


  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));

  let postBodyData = {}
  console.log("isAuthenticated",isAuthenticated)
  if(isAuthenticated===true){   
    const userSavedData = JSON.parse(localStorage.getItem("userecm"));
    const savedCartData = JSON.parse(localStorage.getItem("cartItems"));

    postBodyData.user = userSavedData?.user?._id 
    postBodyData.products = savedCartData

    const authToken = localStorage.getItem("authToken")
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`
      },
    };
  
    await axiosInstanceCookie.post(API_URL.cart, postBodyData, config);
  }
 

 
};

// REMOVE FROM CART
export const removeItemsFromCart = (id) => async (dispatch, getState) => {
 
  dispatch({
    type: REMOVE_CART_ITEM,
    payload: id,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

// SAVE SHIPPING INFO
export const saveShippingInfo = (data) => async (dispatch) => {
  console.log("dispatch",data)
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data,
  });
  // console.log("Data",JSON.stringify(data))
  localStorage.setItem("shippingInfo", JSON.stringify(data));
};


// Add to WISHLIST
export const addItemsToWishlist = (id, quantity = 1) => async (dispatch, getState) => {
  const { data } = await axiosInstance.get(`${API_URL.GetProductDetails}/${id}`);

  dispatch({
    type: ADD_TO_WISHLIST,
    payload: {
      product: data.product._id,
      name: data.product.name,
      price: data.product.price,
      image: data.product.image,
      stock: data.product.Stock,
      quantity,
    },
  });


  localStorage.setItem("wishList", JSON.stringify(getState().cart.wishList));
};

// REMOVE FROM WISHLIST
export const removeItemsFromWishlist = (id) => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_WISHLIST_ITEM,
    payload: id,
  });

  localStorage.setItem("wishList", JSON.stringify(getState().cart.wishList));
};
