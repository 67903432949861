import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { saveShippingInfo } from "../../actions/cartAction";
import { useAlert } from "react-alert";
import ProductInfoCard from './ProductInfoCard'
import { Link, useNavigate } from 'react-router-dom';
// import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { axiosInstance } from '../../Utilites/Axios';
import {createOrder, clearErrors} from "../../actions/orderAction";
import StripeContainer from '../../Stripe/StripeContainer';
import PayuForm from '../../payu-pg/PayuForm';



function Payment() {
  const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const payBtn = useRef(null);

  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.newOrder);

  const [isOpen, setIsOpen] = useState(false)

  // console.log(cartItems)

  const order = {
    shippingInfo,
    orderItems: cartItems,
    itemsPrice: orderInfo.subtotal,
    taxPrice: orderInfo.tax,
    shippingPrice: orderInfo.shippingCharges,
    totalPrice: orderInfo.totalPrice,
    currency: "INR",
    payment_method_types: "payu",
    description:"Ecomm MAHI",
    user:{email:user?.email},
    customer: user._id,

  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    return ()=>{
      setIsOpen(false)
    }
  }, []);

  
  const makePayment = ()=>{
    setIsOpen(true)
  } 
  return (
    <div class="pemtpeg chekoutsec">
    <div class="container">
      <div class="row">
       {/* Product card info right side panal */}
       <ProductInfoCard cartItems={cartItems}/>
        <div class="col-lg-7 order-lg-1">
          <div class="lftbox pe-lg-5 ps-lg-5">
            <div class="cntctshpbx">
              <div class="tpbx01">
                <div class="cgbox01">
                  <p class="txtsmll">Contact</p>
                  <p class="txteml">{shippingInfo?.email}</p>
                </div>
                <div class="cgbox02"> <Link href="/checkout" class="chengbtn">Change</Link> </div>
              </div>
              <div class="bodrlin">border</div>
              <div class="btmbx01 tpbx01">
                <div class="cgbox01">
                  <p class="txtsmll">Ship to</p>
                  <p class="txteml">{`${shippingInfo?.address}, ${shippingInfo?.city}, ${shippingInfo?.country}`}</p>
                </div>
                <div class="cgbox02"><Link href="/checkout" class="chengbtn">Change</Link>  </div>
              </div>
            </div>
            <div class="shpngmethdbox">
              <h3 class="txth3">Payment method</h3>
              <div class="farbox ">
                <div class="prediotxt">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                  </div>
                  <p class="txteml">UPI/ Wallet/ Debit Card/ Credit Card/ Net Banking</p>
                </div>
                <div class="retbx d-none">
                  <p class="retxt">Free</p>
                </div>
              </div>
              {/* { isOpen ? <StripeContainer />  */}
              { isOpen ? <PayuForm checkoutInfo={order} /> 
              
              :  <div class="rcbtnbox mt-2"> 
              <Link to="/checkout" class="rtcbtn"><i class="fa-solid fa-angle-left"></i> Return to information</Link>
                <button type="button" class="btn" onClick={()=>makePayment()}>Make the Payment</button>
              </div>}             
            </div>
          </div>
        
        </div>
         
      </div>
    </div>
  </div>
  )
}

export default Payment