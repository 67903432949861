import { Form, Formik } from 'formik'
import React from 'react'
import Yup from '../../../reusable-component/formik/Yup';
import { clearErrors, login } from "../../../actions/userAction";
import { useDispatch } from 'react-redux';
import FormikController from '../../../reusable-component/formik/FormikController';


function SignInComponent() {

  const dispatch = useDispatch();

  const loginIntialState = {
    email: "",
    password: "",
  }


  const loginValidationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Required")
      .nullable(),
    password: Yup.string()
      .required("Password Required")
      // .matches(
      //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      // ),
  });


  const loginSubmit = (v) => {
    // console.log("e", e)
    dispatch(login(v.email, v.password));
  };




  return (
      <Formik
          initialValues={loginIntialState}
          validationSchema={loginValidationSchema}
          onSubmit={(v) => loginSubmit(v)}>
        {(formik) => (
            <Form className="sign-in-form">
              <h2 className="title">Sign in</h2>
              <div className="input-field">
                <i className="fas fa-user"></i>
                <FormikController
                    control="input"
                    name="email"
                    placeholder="Email"
                />
              </div>
              <div className="input-field">
                <i className="fas fa-lock"></i>
                <FormikController
                    type="password"
                    control="input"
                    placeholder="Password"
                    name="password"
                />
              </div>
              <button type="submit" className="btn solid">Sign In</button>
              <p className="social-text">Or Sign in with social platforms</p>
              <div className="social-media">
                <a href="#" className="social-icon">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#" className="social-icon">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#" className="social-icon">
                  <i className="fab fa-google"></i>
                </a>
                <a href="#" className="social-icon">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </Form>)}
      </Formik>
  )
}

export default SignInComponent