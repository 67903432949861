import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { myOrders as fetchMyOrders } from '../../../actions/orderAction';
import { loadUser, updatePassword } from '../../../actions/userAction';
import moment from 'moment';
import { useAlert } from 'react-alert';
import { clearErrors } from '../../../actions/productAction';
import { UPDATE_PASSWORD_RESET } from '../../../constants/userConstants';
import { useNavigate } from 'react-router';
import { useViewport } from '../../../hooks/useViewPort';
import AccordionWrapper from '../../../reusable-component/accordion/AccordionWrapper';
import AccordionItem from '../../../reusable-component/accordion/AccordionItem';
import './CustomerDashboard.scss';

function CustomerDashboard() {
  const dispatch = useDispatch()
  const alert = useAlert();
  const navigate = useNavigate();
  const { isMobileView } = useViewport();
  const { myOrders, user } = useSelector(state => state);

  useEffect(() => {
    // dispatch(loadUser())
    dispatch(fetchMyOrders());
  }, []);

  const orderList = myOrders?.orders;
  const { error, isUpdated, loading } = useSelector((state) => state.profile);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const updatePasswordSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("oldPassword", oldPassword);
    myForm.set("newPassword", newPassword);
    myForm.set("confirmPassword", confirmPassword);

    dispatch(updatePassword(myForm));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Profile Updated Successfully");
      // navigate("/")
      dispatch({
        type: UPDATE_PASSWORD_RESET,
      });
    }

  console.log("user isAuthenticated",user?.isAuthenticated)

    if(!user?.isAuthenticated){
      navigate("/login")
  }
  }, [dispatch, alert, navigate, isUpdated, user]);

  return (
      <div className="dashboard-wrapper">
        <AccordionWrapper className={isMobileView ? 'vertical' : 'horizontal'}>
          <AccordionItem
              title={'Order History'}
              index={0}
              description={orderList?.length >0 ?
                  orderList?.map((oInfo, i) => (
                      <div className="ordrhistrybx mt-4" key={i}>
                        <div className="ordrhistrymnu" data-bs-toggle="collapse" data-bs-target={`#collapseExample${i}`} aria-expanded="false" aria-controls="collapseExample">
                          <div className="boxsec p-1">
                            <figure className="imgbox mb-0">
                              <img src={oInfo?.orderItems[0]?.image} alt="prouduct name" className="mw-100 d-block" /></figure>
                            <div className="cntntbx">
                              <div className="pdctnm">
                                <h4 className="txth4">{oInfo?.orderItems[0]?.name}</h4></div>
                              <div className="pducrqyt">
                                <h5 className="txth5"><span>Qyt. </span>{oInfo?.orderItems[0]?.quantity}</h5></div>
                              <div className="mrpbx">
                                <h5 className="txth5">Rs. <span>{oInfo?.itemsPrice}</span></h5></div>
                              <div className="sdtbx">
                                {/* <h5 className="txth5">Delivered on Mar 05, 2023</h5> */}
                                <h5 className="txth5">Order Status : {oInfo?.orderStatus}</h5>
                              </div>
                              <div className="vmbtn">
                                <button type="button">View More <i className="fa-solid fa-chevron-down" /></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="collapse" id={`collapseExample${i}`}>
                          <div className="ordrhistrybxdrdwn mt-3">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="pmnthstybx box">
                                  <h5 className="txth5">
                                    Payment Information
                                  </h5>
                                  <p>Payment status : {oInfo?.paymentInfo?.status}</p>
                                  <p>Paid at : {moment(oInfo?.paidAt).format("MM/DD/YYYY h:mm:ss a")}</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="spiifobx box">
                                  <h5 className="txth5">
                                    Shipping Information
                                  </h5>
                                  <p>{`${oInfo?.shippingInfo?.address}, ${oInfo?.shippingInfo?.city}, ${oInfo?.shippingInfo?.state}, ${oInfo?.shippingInfo?.pinCode} `}</p>
                                  <p>Contact Number : {oInfo?.shippingInfo?.phoneNo}</p>
                                </div>
                              </div>
                              <div className="col-md-4 d-none">
                                <div className="pdtifobx box">Product Info</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  )) : <div className="ordrhistrybx mt-4 text-center">

                    <h2>Order Not Found</h2>

                  </div> }>
          </AccordionItem>
          <AccordionItem
              title={'Change password'}
              index={1}
              description={<div className="chngpsbx">
              <div className="input-field">
                <i className="fas fa-lock"></i>
                <input
                    className="form-control"
                    placeholder="Current Password"
                    required
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="input-field">
                <i className="fas fa-lock"></i>
                <input
                    className="form-control"
                    placeholder="New Password"
                    required
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="input-field">
                <i className="fas fa-lock"></i>
                <input
                    className="form-control"
                    placeholder="Re-Enter Password"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="mb-1">
                <button className="primary-cta" type="submit" value="Change">Confirm</button>
              </div>
            </div>}>
          </AccordionItem>
          <AccordionItem
              title={'Address'}
              index={2}
              description={<div className="ordrhstrybx">
                <div className="mb-3">
                  <label htmlFor="exampleFormControlTextarea1" className="form-label">Shipping Address</label>
                  <div className="input-field">
                    <i className="fas fa-address-card"></i>
                    <input
                        className="form-control"
                        placeholder="Shipping Address"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        defaultValue={""} />
                  </div>
                </div>
                <div className="mb-3">
                  <button type="button" className="primary-cta">Submit</button>
                </div>
              </div>}>
          </AccordionItem>
          <AccordionItem
              title={'Profile'}
              index={3}
              description={<div className="prflbx">
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Full Name</label>
                  <div className="input-field">
                    <i className="fas fa-user"></i>
                    <input
                        className="form-control"
                        placeholder="Full Name"
                        id="exampleFormControlInput1"
                        value={user?.user?.name}
                        disabled={true}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput2" className="form-label">Email address</label>
                  <div className="input-field">
                    <i className="fas fa-user"></i>
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        id="exampleFormControlInput2"
                        value={user?.user?.email}
                        disabled={true}
                    />
                  </div>
                  </div>
              </div>}>
          </AccordionItem>
        </AccordionWrapper>
      </div>
  );
}

export default CustomerDashboard;
