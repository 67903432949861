import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, forgotPassword } from "../../actions/userAction";
import { useAlert } from "react-alert";
import { useState } from 'react';
import { useEffect } from 'react';
import { FRONT_URL } from '../../config';


function ForgotPwdComponent() {
	const dispatch = useDispatch();
	const alert = useAlert();
	const navigate = useNavigate();

	const { error, message, loading } = useSelector(
		(state) => state.forgotPassword
	);

	const [email, setEmail] = useState("");

	const forgotPasswordSubmit = (e) => {
		e.preventDefault();

		const myForm = new FormData();

		myForm.set("email", email);
		myForm.set("doamin_hosting_url", FRONT_URL);
		dispatch(forgotPassword(myForm));
		setEmail("")
	};

	useEffect(() => {
		if (error) {
			alert.error(error);
			dispatch(clearErrors());
		}
		if (message) {
			// navigate("/create-password")
			alert.success(message);
		}

		return () =>{
			dispatch(clearErrors());
		}
	}, [message,error,dispatch]);


	return (
		<React.Fragment>
			<div className="forgetpaswrd formsec">
				<div className="container">
					<div className="titlebox">
						<h3 className="txth3">Reset your password</h3>
						<p className="txtpaira">We will send you an email to reset your password</p>
					</div>
					<form className="frmbox" onSubmit={forgotPasswordSubmit}>
						<div className="mb-4">
							<input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email address"
								required
								name="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/> </div>
						<div className="btnbx">
						{loading === false ? 
							<button type="submit" className="btn" value="Send">Submit</button>
							:
							<p>In Process</p> 
						 }
							<div className="ca"><Link to="/login" >Cancel</Link> </div>
						</div>
					</form>
				</div>
			</div>
		</React.Fragment>
	)
}

export default ForgotPwdComponent