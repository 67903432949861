import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addItemsToCart, removeItemsFromWishlist } from '../../../actions/cartAction';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Box from '../../../reusable-component/box/Box';
import './WishList.scss';

function WishList() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { wishList } = useSelector((state) => state.cart);

  const addToCart = (id) => {
  	const newQty = 1;
  	dispatch(addItemsToCart({id, quantity:newQty}));
    alert.success('Added to cart');
  };

  const deleteWishlistItem = (id) => {
  	dispatch(removeItemsFromWishlist(id));
    alert.success('Removed from wish list');
  };

  const renderWishList = () => {
    return (
        <div className="wishlist-content">
          {wishList && wishList.length > 0 ?
              wishList.map((item, i) => (
                  <div className="product" key={i}>
                    <Link className="cart-image" to={`/product-details/${item?.product}`}>
                      <img src={item?.image} alt="product" width={100} />
                    </Link>
                    <div className="item-details">
                      <Link to={`/product-details/${item?.product}`}><h4>{item?.name}</h4></Link>
                      <p><b>Price: </b>  INR {item.price}</p>
                      {item?.stock > 0 &&
                          <button
                              className="primary-cta"
                              onClick={()=>addToCart(item?.product)}>
                            ADD TO CART
                          </button>
                      }
                    </div>
                    <button className="remove" onClick={()=>deleteWishlistItem(item?.product)}>
                      <i className="fa fa-trash-alt" />
                    </button>
                  </div>
              ))
              : (
                  <div className="empty-list">
                    <h3>No Product Found</h3>
                    <p>Be inspired and discover something new to add to your wardrobe.</p>
                    <Link to="/" ><h3>View Categories</h3></Link>
                  </div>
              )
          }
        </div>
    )
  };

  return (
    <div className="wishlist-wrapper">
        <Box
            heading={'MY WISHLIST'}
            content={renderWishList()}
        />
    </div>

  );
}

export default WishList;
