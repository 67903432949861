import React from 'react';
import AllRoutes from "./AllRoutes";
import "./assets/css/style/common/common.scss";
import "../src/assets/css/style/style.scss";
import "./App.css"

const App = () => {
  return (
    <AllRoutes></AllRoutes>
  );
};

export default App;