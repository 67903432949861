import React, { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './HeaderMenu.scss';

const HeaderMenu = () => {
    const { cart } = useSelector(state => state);
    const { cartItems, wishList } = cart;
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!document.getElementById("mySidenav").contains(event.target)) {
                setIsMenuOpen(false);
                document.getElementById("mySidenav").style.width = '0';
                event.preventDefault();
            }
        };

        if (isMenuOpen) {
            document.body.addEventListener('click', handleOutsideClick, true);
        }

        return () => {
            if (isMenuOpen) {
                document.body.removeEventListener('click', handleOutsideClick, true);
            }
        };
    }, [isMenuOpen]);

    function openNav() {
        setIsMenuOpen(true);
        document.getElementById("mySidenav").style.width = "250px";
    }

    return (
        <div className="header-menu">
            <div className="menu-items wishlist-items">
                <NavLink to="/wishlist">
                    <i className="fa fa-heart fa-lg" aria-hidden="true" />
                    {wishList && wishList.length ? <span className="wishlist-count">{wishList.length}</span> : ''}
                </NavLink>
            </div>
            <div className="menu-items cart-items">
                <NavLink to="/cart">
                    <i className="fa fa-shopping-cart fa-lg" aria-hidden="true" />
                    {cartItems && cartItems.length ? <span className="shopping-cart-count">{cartItems.length}</span> : ''}
                </NavLink>
            </div>
            <div className="menu-items">
                <NavLink to="/customer-dashboard">
                    <i className="fa fa-user fa-lg" aria-hidden="true" />
                </NavLink>
            </div>
            <div className="menu-items">
                <span onClick={() => openNav()} style={{ cursor: "pointer" }}>
                  <i className="fa fa-bars fa-lg" aria-hidden="true" />
                </span>
            </div>
        </div>
    );
};

export default HeaderMenu;
