import axios from "axios";

const authToken = localStorage.getItem("authToken")

// export const axiosInstanceCookie = axios.create({ withCredentials: true }); 
// export const axiosInstanceCookie = 
export const axiosInstanceCookie = axios.create({
    withCredentials: true 
});


export const axiosInstance = axios.create({ });
