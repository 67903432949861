import { useEffect, useState } from 'react';

export const useViewport = () => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const [isTabletView, setIsTabletView] = useState(window.innerWidth > 767 && window.innerWidth < 992);

    const handleResize = () => {
        setIsMobileView(window.innerWidth < 768);
        setIsTabletView(window.innerWidth > 767 && window.innerWidth < 992)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {
        isMobileView,
        isTabletView
    };
};
