import React from 'react';
import './Box.scss';

const Box = (props) => {
    return (
        <div className="box-wrapper">
            <div className="box-heading"><h3>{props?.heading}</h3></div>
            <div className="box-body">{props?.content}</div>
        </div>
    );
};

export default Box;
