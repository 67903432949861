import React from 'react'
import Yup from '../../../reusable-component/formik/Yup';
import { Regex, RegexMsg } from '../../../reusable-component/formik/ValidationRegex';
import { Form, Formik } from 'formik';
import FormikController from '../../../reusable-component/formik/FormikController';
import { useDispatch } from 'react-redux';
import { register } from '../../../actions/userAction';

function SignupComponent() {
  const dispatch = useDispatch()

  const signUpIntialState = {
    name: "",
    email: "",
    password: "",
  }


  const SignUpValidationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .matches(Regex.acceptAlphabet, RegexMsg.acceptAlphabet)
      .required("Required")
      .wordLength("Word character length exceeded")
      .max(100, "Maximum 100 characters are allowed")
      .nullable(),
    email: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Required")
      .nullable(),
    password: Yup.string()
      .required("Password Required")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
  });



  const registerSubmit = (v) => {
    console.log("dfdfdf",v)
    // alert(v)
    const myForm = new FormData();

    myForm.set("name", v.name);
    myForm.set("email", v.email);
    myForm.set("password", v.password);
 
    dispatch(register(myForm));
  };




  return (
      <Formik
          initialValues={signUpIntialState}
          validationSchema={SignUpValidationSchema}
          onSubmit={(v) => registerSubmit(v)}>
        {(formik) => (
            <Form className="sign-up-form" >
              <h2 className="title">Sign up</h2>
              <span>Use your email for registration</span>
              <div className="input-field">
                <i className="fas fa-user"></i>
                <FormikController
                    control="input"
                    name="name"
                    placeholder="Full Name"
                />
              </div>
              <div className="input-field">
                <i className="fas fa-envelope"></i>
                <FormikController
                    control="input"
                    name="email"
                    placeholder="Email"
                />
              </div>
              <div className="input-field">
                <i className="fas fa-lock"></i>
                <FormikController
                    type="password"
                    control="input"
                    placeholder="Password"
                    name="password"
                />
              </div>
              <button type="submit" className="btn">Create Account</button>
              <p className="social-text">Or Sign up with social platforms</p>
              <div className="social-media">
                <a href="#" className="social-icon">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#" className="social-icon">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#" className="social-icon">
                  <i className="fab fa-google"></i>
                </a>
                <a href="#" className="social-icon">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </Form>
        )}
      </Formik>
  )
}

export default SignupComponent