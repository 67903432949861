import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login, register } from "../../../actions/userAction";
import { useAlert } from "react-alert";
import FormikController from "../../../reusable-component/formik/FormikController";
import Yup from "../../../reusable-component/formik/Yup";
import { Regex, RegexMsg } from "../../../reusable-component/formik/ValidationRegex";
import { Form, Formik } from "formik";
import { dirImages } from '../../../Utilites/GetImage'
import SignupComponent from "./SignupComponent";
import SignInComponent from "./SignInComponent";
import './Login.scss';

// import "./LoginSignUp.css"

function LoginComponent({ history, location }) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const loginTab = useRef(null);

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const loginSubmit = (e) => {
    console.log("e", e)
    dispatch(login(loginEmail, loginPassword));
  };


  useEffect(() => {
    if (error) {
      alert.error(error);
      // dispatch(clearErrors());
    }

    if (isAuthenticated) {
      // console.log("login success");
      navigate("/");
    }
  }, [isAuthenticated, error]);


  useEffect(() => {
    return () => {
      dispatch(clearErrors());
    }
  }, [])


  const loginPanelClass = useRef(null);

  const handleClick = () => {
    loginPanelClass.current.classList.add("sign-up-mode");
  };


  const handleRemoveClass = () => {
    loginPanelClass.current.classList.remove("sign-up-mode");
  };




  const registerTab = useRef(null);
  const switcherTab = useRef(null);



  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });


  const loginIntialState = {
    email: "",
    password: "",
  }


  const signUpIntialState = {
    name: "",
    email: "",
    password: "",
  }




  const loginValidationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Required")
      .nullable(),
    passwordd: Yup.string()
      .required("Password Required")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
  });



  const SignUpValidationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .matches(Regex.acceptAlphabet, RegexMsg.acceptAlphabet)
      .required("Required")
      .wordLength("Word character length exceeded")
      .max(100, "Maximum 100 characters are allowed")
      .nullable(),

    email: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Required")
      .nullable(),
    passwordd: Yup.string()
      .required("Password Required")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
  });


  const { name, email, password } = user;

  const [avatar, setAvatar] = useState("/Profile.png");
  const [avatarPreview, setAvatarPreview] = useState("/Profile.png");


  const registerSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("email", email);
    myForm.set("password", password);
    myForm.set("avatar", avatar);
    dispatch(register(myForm));
  };

  const registerDataChange = (e) => {
    if (e.target.name === "avatar") {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };

  // const redirect = location.search ? location.search.split("=")[1] : "/account";

  // useEffect(() => {
  //   if (error) {
  //     alert.error(error);
  //     dispatch(clearErrors());
  //   }

  //   if (isAuthenticated) {
  //     history(redirect);
  //   }
  // }, [history, isAuthenticated, redirect]);

  const switchTabs = (e, tab) => {
    if (tab === "login") {
      switcherTab.current.classList.add("shiftToNeutral");
      switcherTab.current.classList.remove("shiftToRight");

      registerTab.current.classList.remove("shiftToNeutralForm");
      loginTab.current.classList.remove("shiftToLeft");
    }
    if (tab === "register") {
      switcherTab.current.classList.add("shiftToRight");
      switcherTab.current.classList.remove("shiftToNeutral");

      registerTab.current.classList.add("shiftToNeutralForm");
      loginTab.current.classList.add("shiftToLeft");
    }
  };







  return (
    <React.Fragment>
      <div className="login-container" ref={loginPanelClass}>
        <div className="forms-container">
          <div className="signin-signup">
            <SignInComponent />
            <SignupComponent />
          </div>
        </div>
        <div className="panels-container">
            <div className="panel left-panel">
              <div className="content">
                <h3>Hello, Friend!</h3>
                <p>Enter your personal details and start journey with us</p>
                <button className="btn transparent" id="sign-up-btn" onClick={handleClick}>
                  Sign up
                </button>
              </div>
              <img src={dirImages['login.svg']} className="image" alt="" />
            </div>
            <div className="panel right-panel">
              <div className="content">
                <h3>Welcome Back!</h3>
                <p>To keep connected with us please login with your personal info</p>
                <button className="btn transparent" id="sign-in-btn" onClick={handleRemoveClass}>
                  Sign in
                </button>
              </div>
              <img src={dirImages['register.svg']} className="image" alt="" />
            </div>
          </div>
      </div>
    </React.Fragment>
  );
}

export default LoginComponent;
