import React from 'react'

function ProductInfoCard({cartItems}) {
  // console.log(cartItems)
  return (
    <div className="col-lg-5 border-start order-lg-2">
              <div className="rytbox ps-lg-5">
                <div className="boxsec">

                  {cartItems &&
                    cartItems.map((item, i) => (
                      <div className="box box1">
                        <div className="produtbox">
                          <picture className="imgbox">
                            <img src={item?.image} alt="product-img" className="d-block mw-100" />
                          </picture>
                          <div className="txtbox">
                            <h5 className="txth5">{item?.name}</h5>
                            <p>{item?.category}</p>
                            <p>{item?.colour}</p>

                          </div>
                        </div>
                        <div className="prisbox">
                          <p>{`INR ${item.price}`}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div className="disuntcodbox d-none">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Gift card or discount code" aria-label="Gift card or discount code" aria-describedby="button-addon2" />
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2">Apply</button>
                  </div>
                </div>

                <div className="sabtotlsec">
                  <div className="sabbox">
                    <p>Subtotal</p>
                    <p className="sabret">{`INR ${cartItems.reduce(
                      (acc, item) => acc + item.quantity * item.price,
                      0
                    )}`}</p>
                  </div>
                  <div className="shpngbox">
                    <p>Shipping <a href="#" className="qicon"><i className="fa-solid fa-circle-question" /></a></p>
                    <p className="nxstp">Free (All over india)</p>
                  </div>
                </div>
                <div className="totlbox">
                  <p className="totltxt">Total</p>
                  <p className="totlret">{`INR ${cartItems.reduce(
                    (acc, item) => acc + item.quantity * item.price,
                    0
                  )}`}
                  </p>
                </div>
              </div>
            </div>
  )
}

export default ProductInfoCard