import React from 'react'
import { Link } from 'react-router-dom'

function TermCond() {
      return (
            <div className="allsrvspeg trmcndtonpeg breadcrumbs-wrapper">
                  <div className="container text-align-justify">
                        <div className="titlbox">
                              <h3 className="txth3">Terms and Conditions</h3></div>
                        <p className="txtmdiam">("You" or "End User" or "your" or "Buyer" or "Customer") are required to read and accept all of the terms and conditions laid down in this Terms and Conditions ("Terms and Conditions" or "TERMS AND CONDITIONS" or "Terms" or "Agreement") and the linked Privacy Policy, before you may use thenoveltyprints.com (hereinafter referred to as "Site" or "thenoveltyprints.com" or "we" or "our"). The Site allows you to browse, select and purchase Clothing and Accessories ("Goods" or "Products" or "Services"). </p>

                     
                        <p className="txtmdiam">The Company may amend this Agreement and/or the Privacy Policy at any time by posting a revised version on the Site. All updates and amendments shall be notified to you via posts on website or through e-mail. The revised version will be effective at the time we post it on the Site, and in the event you continue to use our Site, you are impliedly agreeing to the revised TERMS AND CONDITIONS and Privacy Policy expressed herein. </p>


                        <p className="txtmdiam">In addition, if the revised version of this Agreement includes a Substantial Change, we will provide you with 30 days' prior notice of such Substantial Change as per the Notification Preferences provided by you. You are advised to regularly check for any amendments or updates to the TERMS AND CONDITIONS contained in this Agreement. For the purpose of this Agreement, the term "Substantial Change" means a change to the terms of this Agreement that materially reduces your rights or increases your responsibilities. </p>

                        <p className="txtmdiam">Please read these TERMS AND CONDITIONS carefully. These terms & conditions, as modified or amended from time to time, are a binding contract between the company and you. If you visit, use, or shop at the site (or any future site operated by the company, you accept these TERMS AND CONDITIONS). In addition, when you use any current or future services of the company or visit or purchase from any business affiliated with the company or third party vendors, whether or not included in the site, you will also be subject to the guidelines and conditions applicable to such service or merchant. If these conditions are inconsistent with such guidelines and conditions, such guidelines and conditions will prevail. </p>

                        <p className="txtmdiam">If this TERMS AND CONDITIONS conflicts with any other document, the TERMS AND CONDITIONS will prevail for the purposes of usage of the Site. As a condition of purchase, the Site requires your permission to send you administrative and promotional emails. We will send you information regarding your account activity and purchases, as well as updates about our products and promotional offers. </p>


                        <h4 className="txth4">YOUR CONSENT
                        </h4>
                        <p className="txtmdiam">By using our site, you consent to our online Privacy Policy. </p>
                        <h4 className="txth4">CHANGES TO OUR PRIVACY POLICY
                        </h4>
                        <p className="txtmdiam">If we decide to change our privacy policy, we will post those changes on this page, and/or send an email notifying you of any changes. </p>
                      
                  </div>
            </div>
      )
}

export default TermCond


