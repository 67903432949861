import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { addItemsToCart, removeItemsFromCart } from "../../actions/cartAction";
import { Link, useNavigate } from "react-router-dom";
import './Cart.scss';

function Cart() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cart, user } = useSelector((state) => state);
  const { isAuthenticated } = user
  const { cartItems } = cart

  const increaseQuantity = (id, quantity, stock, size) => {
    const newQty = quantity + 1;
    if (stock <= quantity) {
      return;
    }

    dispatch(addItemsToCart({ id, quantity: newQty, isAuthenticated, productSize: size }));
  };

  const decreaseQuantity = (id, quantity, size) => {
    const newQty = quantity - 1;
    if (1 >= quantity) {
      return;
    }
    dispatch(addItemsToCart({ id, quantity: newQty, isAuthenticated, productSize: size }));
  };

  const deleteCartItems = (id) => {
    dispatch(removeItemsFromCart(id, isAuthenticated));
  };

  const checkoutHandler = () => {
    navigate("/checkout");
  };

  // const cartItems = [{
  //   _id:1,
  //   image:"https://firebasestorage.googleapis.com/v0/b/thenoveltyprints-97bc9.appspot.com/o/products%2F1WM_L.png?alt=media&token=28cb91cc-0ffc-4318-941d-150e85caab2e",
  //   name:"couple mug", 
  //   price:"299", 
  //   Stock:"12",
  //   description:"lorem ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum  ipsum lorem ipsum "
  // }]

  console.log("cartItems",cartItems)
  return (
    <React.Fragment>
      {cartItems.length === 0 ? (
        <div className="cart-empty-bag">
          <div className="container">
            <div className="titlebox">
              <h3 className="txth3">YOUR SHOPPING BAG IS EMPTY</h3>
              <p className="txth3">Be inspired and discover something new to add to your wardrobe.</p>
              <Link to="/" ><h3>View Categories</h3></Link>
            </div>
          </div>
        </div>

      ) : (
        <div className="cart">
          <div className="main-content-mobile">
            <div className="top">
              <p>Your order will Ship for FREE !!</p> <span><Link to="/">Continue Shopping <i className="fa fa-arrow-right" /></Link></span> </div>
            {/* Item Details Begin */}
            <div className="shopping-cart">
              <div className="item-details">
                <div className="heading">
                  <h5>Items Details</h5>
                  <h3>Shopping Cart</h3>
                  <h3>{cartItems?.length} Items</h3> </div>
                <div className="top-row desk">
                  <p>PRODUCT DETAILS</p>
                  <p>QUANTITY</p>
                  <p>TOTAL</p>
                </div>
                {cartItems &&
                  cartItems.map((item, i) => (
                    <div className="product" key={i}>
                      <div className="info">
                        <Link className="cart-image" to={`/product-details/${item?.product}`}>
                          <img src={item?.image} alt="product" width={100} />
                        </Link>
                        <div className="cart-details">
                          <Link to={`/product-details/${item?.product}`}><h4>{item?.name}</h4></Link>
                          <p><b>Price: </b>  INR {item.price}</p>
                          {item.productSize && item.productSize !== 'default' ? <p><b>Size:</b> {item.productSize}</p> : ''}
                          <p>
                            <b>Category: </b>
                            <span className="category">{item.productCategory}</span>
                          </p>
                          <p className="mobile price-col"><b>Total: </b>INR {item.price * item.qty}</p>
                          <div className="mobile">
                            <p className="quantity">
                              <button
                                  className="btn-minus"
                                  onClick={() => decreaseQuantity(item.product, item.qty, item.productSize)}
                              >-</button>
                              <input className="quantity-field" type="text" value={item.qty} readOnly={true} />
                              <button
                                  className="btn-plus"
                                  onClick={() => increaseQuantity(item.product, item.qty, item.stock, item.productSize)}
                              >+</button>
                            </p>
                          </div>
                          <button className="desk primary-cta" onClick={() => deleteCartItems(item.product)}>
                            <i className="fa fa-trash-alt" />  Remove
                          </button>
                        </div>
                        <button className="mobile remove" onClick={() => deleteCartItems(item.product)}>
                          <i className="fa fa-trash-alt" />
                        </button>
                        <div className="desk">
                          <p className="quantity">
                            <button
                                className="btn-minus"
                                onClick={() => decreaseQuantity(item.product, item.qty, item.productSize)}
                            >-</button>
                              <input className="quantity-field" type="text" value={item.qty} readOnly={true} />
                              <button
                                  className="btn-plus"
                                  onClick={() => increaseQuantity(item.product, item.qty, item.stock, item.productSize)}
                              >+</button>
                          </p>
                        </div>
                        <p className="desk price-col">INR {item.price * item.qty}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {/* Item Details End */}
            <div className="order-summary order_summary_bg">
              <div className="title desk">
                <h3>Order Summary</h3>
              </div>
              <div className="items desk">
                <p>Items {cartItems.length}</p>
                <p> {`INR ${cartItems.reduce(
                        (acc, item) => acc + item.qty * item.price,
                        0
                    )}`}
                </p>
              </div>
              <div className="promo-form">
                <p className="desk">PROMO CODE</p>
                <input type="text" placeholder="Have a Promo Code?" />
                <button>Apply</button>
              </div>
              <div className="receipt">
                <div className="row subtotal">
                  <p>Subtotal</p>
                  <p>{`INR ${cartItems.reduce(
                          (acc, item) => acc + item.qty * item.price,
                          0
                      )}`}
                  </p>
                </div>
                <div className="row shipping">
                  <p>Shipping &amp; Handling</p>
                  <p>FREE</p>
                </div>
                <div className="row mobile">
                  <p>Total</p>
                  <p>{`INR ${cartItems.reduce(
                          (acc, item) => acc + item.qty * item.price,
                          0
                      )}`}
                  </p>
                </div>
              </div>
              <div className="total desk">
                <p>Total Cost</p>
                <p>{`INR ${cartItems.reduce(
                        (acc, item) => acc + item.qty * item.price,
                        0
                    )}`}
                </p>
              </div>
              <div className="cta">
                <button className="checkout primary-cta" onClick={checkoutHandler}> Proceed to Checkout </button>
                <Link to="/" className="primary-cta desk">Continue Shopping <i className="fa fa-long-arrow-alt-right" /></Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Cart